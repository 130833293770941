<template>
    <div class="queryMakeBox">
        <van-nav-bar title="预约查询" safe-area-inset-top  placeholder fixed  left-text="返回" left-arrow @click-left="$router.go(-1)" /> 
        <div class="BXI">
             <van-tabs type="card" color='#1989fa' animated sticky offset-top='1.22667rem' v-model="LA_AuditStatus" @change="changeTab">
                <van-tab title="正在预约" :name="0">
                    <list-conpents ref="makeIng"  :LA_AuditStatus='LA_AuditStatus'></list-conpents>
                </van-tab>
                <van-tab title="完成预约" :name="1">
                    <list-conpents ref="make" :LA_AuditStatus='LA_AuditStatus'></list-conpents>
                </van-tab>
                <van-tab title="驳回预约" :name="2">
                    <list-conpents ref="reject" :LA_AuditStatus='LA_AuditStatus'></list-conpents>
                </van-tab>
            
            </van-tabs>
        </div>
    </div>
</template>
<script>
import { NavBar,Tab, Tabs ,List, Image as VanImage } from 'vant';
import ListConpents from './ListConpents'

export default {
    components:{
        [NavBar.name]: NavBar,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [List.name]: List,
        [VanImage.name]: VanImage,
        ListConpents:ListConpents
    },
    data() {
        return {
            LA_AuditStatus:0        
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.$refs.makeIng.CheckList()
        })
     
    },
    methods:{
        changeTab(val){
            if(val === 0){
                this.$nextTick(()=>{
                     this.$refs.makeIng.CheckList()
                })
            }else if(val === 1) {
                this.$nextTick(()=>{
                     this.$refs.make.CheckList()
                })
            }else if(val === 2){
                this.$nextTick(()=>{
                     this.$refs.reject.CheckList()
                })
            }else {
                console.error('出现未知错误')
            }
        },
    }
}
</script>
<style lang="scss" scoped>
    .van-tabs--card {
        /deep/ .van-tabs__wrap {
              height: 35px;
        }
        /deep/ .van-tabs__nav--card {
            margin: 0;
            height: 35px;
            line-height: 35px;
        }
    }
    .EventBox {
        padding: 3px 0px;
        background: #fff;
        display: flex;
        flex-direction: row;
        .ImgBox {
            overflow: hidden;
            padding:6px;
            /deep/ .van-image__img {
                display: inline-block;
                border-radius: 5px;
            }
        }
        .BoxEvent {
            flex: 1;
            padding:6px 5px;
            padding-left: 8px;
            font-size: 13px;
            color: #333;
            .RowtIitle {
                line-height: 25px;
                display: flex;
                flex-direction: row;
                position: relative;
                strong {
                    padding-right: 3px;
                }
                p:first-child {
                    width: 48%;
                }
                p:last-child {
                    flex: 1;
                }
                .StateBox {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    color:#07c160;
                }
                .acitve {
                    color: #ee0a24;
                }
            }
        }
    }
    
</style>